const sliderdata = {
  active: [{ photu: "/assets/slider/Artboard 1.webp" }],
  inactive: [
    { photu: "/assets/slider/Artboard 2.webp" },
    { photu: "/assets/slider/Artboard 3.webp" },
    { photu: "/assets/slider/Artboard 5.webp" },
    { photu: "/assets/slider/Artboard 6.webp" },
    { photu: "/assets/slider/Artboard 7.webp" },
    { photu: "/assets/slider/Artboard 8.webp" },
  ],
};
export default sliderdata;
