const generalrules = ["The 21st VTU Youth Festival will be held from 29th to 31st July 2022.", 
"Registrations will open from 25th June 2022. Last date for participating teams to register is 11th July 2022.",
"Late entries will not be accepted under any circumstances.",
"Requests for changes in registration details after submission will not be entertained whatsoever.",
"The maximum contingent size inclusive of officials, participants and accompanists is fixed at 45.",
"Registration fees for participation in upto 10 events will be Rs. 4000/- and for 11 events and above will be Rs. 8000/-.",
"Payment shall be made through Net Banking only. The account details will be provdided during registration.",
"Accommodation facilities will be provided for the contingents that opt for it on need-basis only. Meals will be provided for all participants and officials. Contingents are advised to bring light bedding and locks.",
"Accommodation will only be provided to the registered contingent members during dates of the fest only.",
"All necessary musical instruments and other materials required for the performances shall be brought by the participants/contingent members.",
"The accompanying Team Manager shall be a faculty having minimum of 5 years experience and/or shall be the Physical Education Director.",
"Every college must pay cash of Rs. 3000/- as a caution deposit at arrival.",
"Usage of any form of Tobacco, Alcohol or Narcotic substances is entirely prohibited.",
"All members of the contingent are expected to maintain discipline and decorum inside and outside the college premises. Failure to do so or engagement in any misadventure will result in penal action.",
];
export default generalrules;
