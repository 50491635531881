const rules = {
  music: [
    {
      name: "Classical Vocal Solo (Hindustani/Carnatic)",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 15 minutes (including set-up and clearance time).",
        "Maximum of 2 accompaniments are allowed, excluding a Shruthi box.",
        "Karaoke is strictly not allowed.",
        "The item can be presented in either Hindustani or Carnatic style.",
        "Film songs are not allowed for this competition.",
        "Reporting time will be provided along with the schedule of the event.",
        "Sufficient thought and care must be exercised in the choice of Raga and composition.",
        "Decision of the panel of judges will be final and binding upon all.",
      ],
    },
    {
      name: "Classical Instrumental Solo (Percussion Tala Vadya)",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 15 minutes (including set-up and clearance time).",
        "A maximum of 2 accompaniments are allowed, excluding a Shruthi Box.",
        "Participants shall bring their own instruments. Keyboards are not allowed.",
        "The item can be presented in either Hindustani or Carnatic style.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Classical Instrumental Solo (Non-Percussion Swara Vadya)",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 15 minutes (including set-up and clearance time). ",
        "A maximum of 2 accompaniments are allowed, excluding a Shruthi Box.",
        "Participants shall bring their own instruments. Keyboards are not allowed.",
        "The item can be presented in either Hindustani or Carnatic style.",
        "Instruments of western origin adopted to the Indian Raga system are allowed.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Light Vocal Solo (Indian)",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 7 minutes (including set-up and clearance time).",
        "A maximum of 2 accompaniments are allowed, excluding a Shruthi box.",
        "Only non-film songs can be presented. [Examples: Geet, Ghazal, Bhajan, Bhavageethe, Shabad and Abhangs].",
        "Karaoke is strictly not allowed.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Western Vocal Solo",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 7 minutes (including set-up and clearance time).",
        "A maximum of 2 accompaniments are allowed.",
        "The song(s) must only be in English.",
        "Karaoke or Programmed Music is strictly not allowed.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Group Song (Indian)",
      rules: [
        "Participation: Only 1 team (upto 6 participants) per institute.",
        "Duration: 15 minutes (including set-up and clearance time).",
        "A maximum of 3 accompanists are allowed.",
        "A team has to present two songs, one patriotic and one folk.",
        "Only Indian languages will be used for group songs. The lyrics of the songs transliterated in English must be submitted at the reporting time to the event incharge.",
        "Film songs are strictly not allowed.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Group Song (Western)",
      rules: [
        "Participation: Only 1 team (upto 6 participants) per institute.",
        "Duration: 15 minutes (including set-up and clearance time).",
        "A maximum of 3 accompanists are allowed.",
        "The song(s) must only be in English.",
        "Karaoke or Programmed Music is strictly not allowed.",
        "Drums will be provided by the host institute.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Folk Orchestra",
      rules: [
        "Participation: Only 1 team (upto 12 participants) per institute.",
        "Duration: 15 minutes (including set-up and clearance time).",
        "A maximum of 3 accompanists are allowed. They should be dressed differently from the participants for ease of identification.",
        "The accompanists shall sit or stand separately from the participants and shall not lead the team.",
        "The team should present only folk tunes, preferably the ones recognized as folk tunes of Karnataka.",
        "Vocal singing is not allowed, however teams may use vocal punctuations as a chorus.",
        "Use of musical instruments backed by electrical power, amplifiers, etc., are not allowed.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
  ],
  dance: [
    {
      name: "Folk / Tribal Dance",
      rules: [
        "Participation: Only 1 team (upto 10 participants) per institute.",
        "Duration: 15 minutes (including set-up and clearance time).",
        "A maximum of 5 accompanists are allowed.",
        "The dance form can be either folk or tribal (Indian Style) but not a classical form.",
        "Three copies, containing a brief description of the performance is to be submitted in a typed format in English at the reporting time to the event incharge.",
        "Pre-recorded music in MP3 format is to be submitted in a pendrive at the reporting time to the event incharge.",
        "The participating team will be entirely responsible for removal of their sets, props, etc., immediately after the completion of their performance.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decision of the organizers and the panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Classical Dance Solo",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: Maximum 12 minutes (including set-up and clearance time).",
        "A maximum of 3 accompanist is allowed.",
        "The classical dance can be from any of the approved schools of dance such as Kathak, Kathakali, Bharat Natyam, Sattriya, Manipuri, Kuchipudi, Mohiniattam, Odissi and Chhau.",
        "Three copies, containing a brief explanation of the theme is to be submitted in a typed format in English at the reporting time to the event incharge.",
        "Pre-recorded music in MP3 format is to be submitted in a pendrive at the reporting time to the event incharge.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decision of the organizers and the panel of judge(s) will be final and binding.",
      ],
    },
  ],
  literary: [
    {
      name: "Quiz",
      rules: [
        "Participation: Only 1 Team (upto 3 participants) per institute.",
        "There will be a written preliminary round through which teams will be selected for the final round.",
        "Finals will be in standard quizzing format which will be explained by the quiz master.",
        "The specific rules regarding evaluation procedure, time to reply to a particular question and the type of round will be given before the start of the round.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decision of the organisers and the panel of judge(s) / quizmaster will be final and binding.",
      ],
    },
    {
      name: "Debate",
      rules: [
        "Participation: Only 1 Team of 2 participants per institute.",
        "Duration: 5 minutes per speaker (10 minutes per team).",
        "The competition will be held in two rounds, preliminary and finals.",
        "The final round will follow British Parliamentary debate format.",
        "The topics for both rounds will be given on spot with appropriate preparation time.",
        "Only English shall be the medium of presentation.",
        "Teams will alternate between speakers.",
        "Reading off a paper is not allowed.",
        "Reporting time will be provided along with the schedule of the event. ",
        "Decision of the organisers and the panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Elocution",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 5 minutes.",
        "The participant shall present either prose or poetry and not a song.",
        "The sequence of speakers will be decided by a draw of lots.",
        "Medium of expression shall be English only.",
        "Subject/Topic of elocution will be announced a day in advance.",
        "Reading off a paper is allowed but not recommended, and will adversely affect points.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decision of the organisers and the panel of judge(s) will be final and binding.",
      ],
    },
  ],
  theatre: [
    {
      name: "Skit",
      rules: [
        "Participation: Only 1 Team (upto 6 participants) per institute.",
        "Duration: 10 minutes (including set-up and clearance time).",
        "A maximum of 3 accompanists are allowed. ",
        "Use of make-up, backdrops and background music is allowed. ",
        "Each team should submit three copies of the synopsis of the skit in the language of presentation (English, Hindi or Kannada) at the reporting time to the event incharge.",
        "Defamation, derogation and belittlement will not be entertained.",
        "Profanity, suggestive speech, euphemisms and vulgarity in action or speech is strictly prohibited. Satire and humour that is devoid of the above is accepted.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organisers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Mime",
      rules: [
        "Participation: Only 1 Team (upto 6 participants) from each institute.",
        "Duration: 6 minutes.",
        "A maximum of 2 accompanists are allowed. ",
        "Background music is to be pre-recorded and submitted in a pendrive in MP3 format at the reporting time to the event incharge.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organisers and panel of judge(s) will be final and binding.",
      ],
    },

    {
      name: "Mimicry",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 5 minutes.",
        "Participants may mimic voices and speech of well known personalities, as well as other common sounds.",
        "Profanity, suggestive speech, euphemisms and vulgarity in action or speech is strictly prohibited. Satire and humour that is devoid of the above is accepted.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organisers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "One Act Play",
      rules: [
        "Participation: Only 1 Team (upto 9 participants) per institute.",
        "Duration: 30 minutes (performance time) + 10 minutes (set-up and clearance time).",
        "A maximum of 3 musical accompanists and 2 technical accompanists are allowed.",
        "Lighting, and basic furniture will be provided on prior request (subject to availability). All other props and paraphernalia such as costumes, make up, stage decorations, backdrops, etc., shall be the responsibility of the team.",
        "The preferred language for the act would be English, Hindi or Kannada. The synopsis of the play in English, Hindi or Kannada must be submitted at the reporting time to the event incharge.",
        "Accompanists shall either speak from the background or play upon musical instruments for background music. They shall be required to appear on the stage only during curtain call.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organisers and panel of judge(s) will be final and binding.",
      ],
    },
  ],
  "fine-arts": [
    {
      name: "Collage",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 2 hours 30 minutes.",
        "The artwork shall be made on the spot on the given topic.",
        "Only one standard A2 size paper will be provided by the host institute.",
        "Collage has to be prepared from old magazines, newspapers, paints and markers only.",
        "Participants shall bring their own scissors, glue, magazines, newspapers and other materials required for the contest.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Rangoli",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 2 hours 30 minutes.",
        "Participants shall bring their own material.",
        "The participants shall prepare a Rangoli within the space provided by the organizers, by free hand only.",
        "Only one of the following meduium shall be used - Poster Colours, Flower Petals, Saw-dust, Pulses or Rice without pasting.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decision of the panel of judges will be final and binding.",
      ],
    },
    {
      name: "Cartooning",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 2 hours 30 minutes.",
        "The artwork will be made on the spot on the given topic/idea.",
        "Only one standard A2 size paper will be provided by the host institute.",
        "All writing or drawing instruments have to be brought by the participant.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Installation",
      rules: [
        "Participation: Only 1 team (upto 4 participants) per institute.",
        "Duration: 2 hours 30 minutes.",
        "The artwork shall be made on the spot on the given topic.",
        "Materials or products required for the competition shall be brought by the participant. They can also use material which they use in other art compositions like Cartooning, Painting, Rangoli, Poster Making, Collage and Clay Modeling.",
        "Participants can also use waste materials which are aviailable in the surroundings. However, they are not allowed to use any already composed images or forms available in the market. They should compose or create their own image with the raw material.",
        "The space for the installation shall be provided by the host institute.",
        "The participant shall create and install an atmosphere realted to the subject or title of the installation in the assigned area. The maximum size of the installation shall be 5'x 5'x 5'.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Poster Making",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 2 hours 30 minutes.",
        "The artwork will be made on the spot on the given topic.",
        "Only one standard A2 size paper will be provided by the host institute.",
        "The participant shall bring their own scissors, glue and other materials required for the event.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Clay Modelling",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 2 hours 30 minutes.",
        "The artwork will be made on the spot on the given topic.",
        "The topics and other specific rules shall be announced on the spot.",
        "2 kg of natural clay shall be provided by the host institute. No additional clay can be used.",
        "Die and moulds are strictly not allowed.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "On Spot Painting",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 2 hours 30 minutes.",
        "The artwork shall be made on the spot on the given topic.",
        "Only one standard A2 size paper will be provided by the host institute.",
        "Types of paints allowed are watercolors, poster paints, oil paints and pastel colors.",
        "Candidates shall bring their own materials such as brushes, paints, palettes, etc.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
    {
      name: "Spot Photography",
      rules: [
        "Participation: Only 1 participant per institute.",
        "Duration: 4 hours.",
        "The participant shall get their own digital camera and memory card. The memory card shall be formatted by the judges before the commencement of the contest.",
        "Mobile phones, drones and other forms of image capturing technologies are not allowed.",
        "The participant has to capture 5 photographs on the theme announced on the spot by the judges.",
        "No mixing, matching or morphing of photographs will be permitted.",
        "Image manipulation software such as Photoshop, Gimp, etc. for enhancing images are not permitted.",
        "The subject of the photo should be within defined geographic limits.",
        "The organizers shall have the rights for the use of these pictures at their discretion.",
        "Any additional instructions will be announced on the spot.",
        "Reporting time will be provided along with the schedule of the event.",
        "Decisions of the organizers and panel of judge(s) will be final and binding.",
      ],
    },
  ],
};
export default rules;
