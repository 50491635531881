const colorDict = {
  music: "alert-inf",
  dance: "alert-dange",
  literary: "alert-dar",
  theatre: "alert-warnin",
  "fine-arts": "alert-succes",
  developers: "alert-dar",
  "design team": "alert-dange",
};
export default colorDict;
